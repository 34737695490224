<template>
  <c-box
    style="background-color: white"
    borderRadius="5px"
    p="5"
    m="0"
    w="220px"
  >
    <c-flex>
      <c-image
        style="border-radius: 8px"
        h="50px"
        w="50px"
        mb="3"
        alt="profile picture"
        :src="company.logo ?? require('@/assets/img/company.png')"
      />
      <c-flex
        @click="goToProfile(company.id)"
        ml="3"
        flexDirection="column"
        align="start"
      >
        <c-text class="person_name" fontSize="14px" fontWeight="700"
          >{{ company.name }}
        </c-text>
        <c-text class="person_name" fontSize="12px" fontWeight="600"
          >{{ company.city }}, {{ company.country }}</c-text
        >
        <!-- <c-text fontSize="13px" mb="3" fontWeight="400">{{
          connection.role
        }}</c-text> -->
      </c-flex>
    </c-flex>
    <c-flex>
      <!--      <c-button-->
      <!--          variant-color="vc-orange"-->
      <!--          variant="outline"-->
      <!--          size="sm"-->
      <!--      >Message</c-button-->
      <!--      >-->
      <c-button @click="followCompany" variant-color="vc-orange" size="sm">
        {{ companyFollowed || followingCompany ? 'Unfollow' : 'Follow' }}
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
import { followCompany, unFollowCompany } from '@/services/company';
import { mapGetters } from 'vuex';

export default {
  name: 'ConnectionCard',
  props: ['company'],
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    followingCompany() {
      return this.company.company_followers.some(
        (flw) => flw.follower.id === this.user.id
      );
    },
  },
  data() {
    return {
      companyFollowed: false,
    };
  },
  methods: {
    goToProfile(companyId) {
      this.$router.push({ name: 'CompanyProfile', params: { companyId } });
    },
    async followCompany() {
      if (this.followingCompany || this.companyFollowed) {
        try {
          await unFollowCompany({
            companyId: this.company.id,
            userId: this.user.id,
          });
          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.companyFollowed = false;
        } catch (e) {
          console.log(e);
          this.companyFollowed = true;
        }
      } else {
        try {
          await followCompany(this.company.id);
          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.companyFollowed = true;
        } catch (e) {
          this.companyFollowed = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.person_name {
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  height: 18px;
}
button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
