<template>
  <div class="p-5">
    <c-box p="5" borderRadius="sm" mb="20px" style="background-color: white">
      <div>
        <c-text fontWeight="600" mb="5" fontSize="15px">Personal</c-text>
      </div>
      <c-flex w="65%" justify="space-between">
        <div>
          <c-flex>
            <c-icon mr="10px" color="vc-orange.500" name="envelope" />
            <c-text mt="-4px" fontWeight="500" mb="3" fontSize="15px"
              >{{user.email}}</c-text
            >
          </c-flex>
          <c-flex>
            <c-icon mr="10px" color="vc-orange.500" name="map-pin" />
            <c-text mt="-4px" fontWeight="500" mb="3" fontSize="15px"
              >{{user.city}} {{user.country}}</c-text
            >
          </c-flex>
        </div>
        <div>
          <c-flex>
            <c-icon mr="10px" color="vc-orange.500" name="phone" />
            <c-text mt="-4px" fontWeight="500" mb="3" fontSize="15px"
            >{{user.phoneNumber}}</c-text
            >
          </c-flex>
<!--          <c-flex>-->
<!--            <c-icon mr="10px" color="vc-orange.500" name="suitcase" />-->
<!--            <c-text :key="comp.id" v-for="comp in user.companies" mt="-4px" fontWeight="500" mb="3" fontSize="15px"-->
<!--              >  {{comp.name}} {{' '}} </c-text-->
<!--            >-->
<!--          </c-flex>-->
        </div>
      </c-flex>
    </c-box>

    <c-box p="5" borderRadius="sm" mb="20px" style="background-color: white">
      <c-text fontWeight="600" mb="5" fontSize="15px">Bio</c-text>
      <c-text fontSize="sm" fontWeight="400" lineHeight="27px">
       {{user.bio ? user.bio : 'Nothing added yet'}}
      </c-text>
    </c-box>
  </div>
</template>

<script>
export default {
  name: 'AboutTab',
  props: ['user']
};
</script>

<style scoped></style>
