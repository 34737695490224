<template>
  <c-box w="100%" style="border-radius: 8px">
    <div v-if="userData ?? companyData">
      <c-box
        boxShadow="1px 1px 4px rgba(75, 102, 171, 0.20)"
        backgroundColor="#dfe9f866"
      >
        <c-box
          :bg="
            companyData
              ? companyData.coverImage ?? 'rgba(255,95,0,0.47)'
              : userData.coverImage ?? 'rgba(255,95,0,0.47)'
          "
          style="height: 261px; object-fit: contain"
          :style="
            !backgroundImage.url
              ? {
                  backgroundImage: isCompany
                    ? `url('${companyData.coverImage}')`
                    : `url('${userData.coverImage}')`,
                }
              : { backgroundImage: `url('${backgroundImage.url}')` }
          "
          w="100%"
          rounded="lg"
        >
          <div v-if="isCompanyAdmin || isAuthUser" class="upload_cover">
            <input
              type="file"
              ref="fileInput"
              @change="onBackgroundChange"
              style="display: none"
            />
            <c-icon-button
              color="000000"
              variant-color="gray"
              aria-label="change banner"
              @click="openFileDialog"
              icon="pen"
            />
          </div>
        </c-box>
        <div class="profile_i_sectn">
          <input
            type="file"
            ref="profileFileInput"
            @change="onProfileChange"
            style="display: none"
            v-if="isAuthUser || isCompanyAdmin"
          />
          <img
            v-if="uploadedProfile.url"
            :src="uploadedProfile.url"
            alt="profile"
          />
          <div style="position: relative" v-else>
            <img
              :src="
                isCompany
                  ? companyData.logo ?? require('@/assets/img/company.png')
                  : userData.profilePhoto ?? require('@/assets/img/user.jpg')
              "
            />
            <c-flex v-if="isCompanyAdmin || isAuthUser" justify="center">
              <c-button
                @click="openImageChange"
                size="xs"
                style="position: absolute; bottom: 5px"
                variant-color="vc-orange"
                >change</c-button
              >
            </c-flex>
          </div>
          <div class="follows">
            <c-text
              fontWeight="bold"
              color="#002B49"
              :fontSize="['12px', '14px', '16px']"
              >{{
                isCompany
                  ? companyData.name
                  : `${userData.firstname} ${userData.lastname}`
              }}</c-text
            >

            <div class="profile_foll">
              <div class="follow_count">
                <c-text
                  fontWeight="400"
                  mr="15px"
                  :fontSize="['8px', '10px', '12px']"
                  color="#002B49"
                  >{{ followersCount }}
                  {{ followersCount === 1 ? 'follower' : 'followers' }}</c-text
                >
                <c-text
                  v-if="!isCompany"
                  fontWeight="400"
                  mr="15px"
                  :fontSize="['8px', '10px', '12px']"
                  color="#002B49"
                  >{{ followingCount }} Following</c-text
                >
                <!-- <c-text fontWeight="400" fontSize="15px" color="#002B49"
                  >2M Likes</c-text
                > -->
              </div>

              <div class="follow_btn">
                <div style="margin-top: -5px" v-if="isAuthUser">
                  <c-button
                    variant="ghost"
                    variant-color="orange"
                    aria-label="change banner"
                    @click="openEdit = true"
                    icon="pen"
                  >
                    Edit Profile
                    <c-icon class="ml-2" name="pen" />
                  </c-button>
                </div>
                <c-button
                  v-if="!isCompany && !isAuthUser"
                  variant-color="vc-orange"
                  mr="5"
                  variant="outline"
                  size="sm"
                  @click="sendQuickMessage(userData)"
                  >Message</c-button
                >
                <c-button
                  v-if="!isAuthUser && !isCompany"
                  variant-color="vc-orange"
                  mr="5"
                  size="sm"
                  :is-loading="btnLoading"
                  @click="follow"
                >
                  {{
                    followed || followingUser ? 'Unfollow' : 'Follow'
                  }}</c-button
                >
                <c-button
                  v-if="!isAuthUser && isCompany"
                  variant-color="vc-orange"
                  mr="5"
                  size="xs"
                  :is-loading="btnLoading"
                  @click="followCompany"
                >
                  {{
                    companyFollowed || followingCompany ? 'Unfollow' : 'Follow'
                  }}</c-button
                >
              </div>
            </div>
          </div>
        </div>

        <c-grid mt="5" w="100%" template-columns="repeat(2, 1fr)" gap="6">
          <c-box w="100%" py="4" px="4">
            <div v-if="isCompany">
              <c-text fontSize=".8rem"
                >{{ companyData.country }} | {{ companyData.city }}</c-text
              >
            </div>
            <c-flex mt="4">
              <!-- <c-link
                borderRadius="15px"
                px="4"
                py="1"
                textDecoration="none"
                borderWidth="1px"
                borderColor="#ef5323"
                color="vc-orange.400"
                bg="white"
                fontSize="xs"
                fontWeight="600"
                v-if="isCompany"
                @click="goToBusinessPlan"
              >
                View Business Plan
              </c-link> -->
            </c-flex>
          </c-box>
        </c-grid>
      </c-box>
      <br />
      <c-box
        boxShadow="1px 1px 4px rgba(75, 102, 171, 0.20)"
        backgroundColor="#dfe9f866"
      >
        <c-tabs variant-color="vc-orange">
          <c-tab-list>
            <c-tab>Posts</c-tab>
            <c-tab>About</c-tab>
            <!-- <c-tab>Mentions</c-tab> -->
            <c-tab v-if="!isCompany">Companies</c-tab>
            <c-tab v-if="isCompany">Team</c-tab>
            <c-tab>Connections</c-tab>
          </c-tab-list>

          <c-tab-panels>
            <c-tab-panel>
              <activity :posts="posts" />
            </c-tab-panel>
            <c-tab-panel>
              <elevator-pitch
                v-if="isCompany"
                :text="companyData.elevatorPitch"
              />

              <AboutTab v-if="!isCompany" :user="userData" />
            </c-tab-panel>
            <!-- <c-tab-panel>
              <MentionsTab />
            </c-tab-panel> -->
            <c-tab-panel v-if="!isCompany">
              <company-card :companies="userData.companies" />
            </c-tab-panel>
            <c-tab-panel v-if="isCompany">
              <team-card :members="members" />
            </c-tab-panel>
            <c-tab-panel v-if="isCompany">
              <CompanyNetworkTab :is-followed="companyFollowed" />
            </c-tab-panel>
            <c-tab-panel v-if="!isCompany">
              <NetworkTab
                :is-followed-user="followed"
                @getFollowingData="getFollowingData"
              />
            </c-tab-panel>
          </c-tab-panels>
        </c-tabs>
      </c-box>
      <!-- <c-stack :spacing="10" v-if="false">
        <c-grid
          w="100%"
          :template-columns="{ base: '1fr', lg: 'repeat(2, 1fr)' }"
          gap="12"
        >
          <market-plan />
          <about />
        </c-grid>
      </c-stack> -->

      <EditProfileModal
        v-if="!isCompany"
        :openEditProfile="openEdit"
        @close="openEdit = false"
        :userData="userData"
      />
    </div>
    <c-flex v-else justify="center" align="center">
      <c-spinner size="xl" color="orange.500" thickness="2px"></c-spinner>
    </c-flex>

    <c-modal
      :is-open="showCropped"
      :on-close="closeCroppedModal"
      :closeOnEsc="true"
      :closeOnOverlayClick="true"
      isCentered
    >
      <c-modal-content
        ref="content"
        w="35em"
        maxWidth="100%"
        borderRadius="8px"
        overflow="hidden"
      >
        <c-modal-close-button />

        <cropper
          class="cropper"
          ref="cropper"
          :src="croppedImage"
          :stencil-props="{
            aspectRatio: 10 / 12,
          }"
        />
        <c-button variant-color="vc-orange" my="4" @click="crop">
          Crop and upload
        </c-button>
      </c-modal-content>
    </c-modal>
  </c-box>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import {
  getCompany,
  updateCompany,
  followCompany,
  unFollowCompany,
} from '@/services/company';
import TeamCard from '@/views/App/Profile/components/TeamCard.vue';
import ElevatorPitch from '@/views/App/Profile/components/ElevatorPitch.vue';
import AboutTab from './tabs/AboutTab.vue';
import CompanyNetworkTab from './tabs/CompanyNetworkTab.vue';
// import MarketPlan from '@/views/App/Profile/components/MarketPlan.vue';
import Activity from '@/views/App/Profile/components/tabs/Activity.vue';
import { getCompanyMembers } from '@/services/foundation';
import { getCompanyPosts, getUsersPosts } from '@/services/insight';
import CompanyCard from './CompanyCard.vue';
// import MentionsTab from './tabs/MentionsTab.vue';
import NetworkTab from './tabs/NetworkTab.vue';
import EditProfileModal from './EditProfileModal.vue';
import messagingMixin from '@/mixins/messaging.js';
import {
  followUser,
  updateUser,
  getUser,
  unFollowUser,
  getUsersFollowers,
} from '@/services/user';
import { uploadFile } from '@/services/common';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'Profile',
  mixins: [messagingMixin],
  components: {
    TeamCard,
    ElevatorPitch,
    AboutTab,
    // MarketPlan,
    Activity,
    CompanyCard,
    NetworkTab,
    CompanyNetworkTab,
    EditProfileModal,
    Cropper,
  },
  props: {
    isCompany: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number || String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      croppedImage: '',
      showCropped: false,
      uploadedProfile: {
        url: '',
        file: null,
      },
      backgroundImage: {
        url: '',
      },
      openEdit: false,
      btnLoading: false,
      followed: false,
      companyData: null,
      userData: null,
      members: [],
      posts: [],
      hideSidebar: false,
      followersLimit: 6,
      followersOffset: 0,
      followersCount: 0,
      followingCount: 0,
      companyFollowed: false,
      userFollowers: [],
      user_detail: {
        date: '',
      },
    };
  },
  watch: {
    $route() {
      if (this.isCompany) {
        this.getCompanyData();
        // this.getFoundationData();
        this.getTeam();
        this.getCompanyPosts();
      } else {
        this.getUserData();
        this.getUserPosts();
      }
    },
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    followingCompany() {
      return this.companyData.company_followers.some(
        (flw) => flw.follower.id === this.user.id
      );
    },
    followingUser() {
      return this.userFollowers.some((flu) => flu.follower.id === this.user.id);
    },
    isAuthUser() {
      return parseInt(this.$route.params?.userId) === this.user.id;
    },
    isCompanyAdmin() {
      if (this.isCompany) {
        const currentTeam = this.user.teams.filter((tm) => {
          return tm.companyId === this.user.lastAccessedCompanyId;
        })[0];
        if (currentTeam) {
          return (
            this.companyData.id === this.user.lastAccessedCompanyId &&
            currentTeam.role === 'ADMIN'
          );
        } else {
          return false;
        }
      }
      return false;
    },
  },
  created() {
    if (this.isCompany) {
      this.getCompanyData();
      // this.getFoundationData();
      this.getTeam();
      this.getCompanyPosts();
    } else {
      this.getUsersFollowers();
      this.getUserData();
      this.getUserPosts();
    }
  },
  methods: {
    ...mapMutations({
      updateUserState: 'auth/updateUser',
    }),
    getFollowingData(followersCount, followingCount) {
      this.followersCount = followersCount;
      this.followingCount = followingCount;
    },
    async getUsersFollowers() {
      try {
        const usrFollws = await getUsersFollowers({
          userId: this.$route.params?.userId,
          limit: this.followersLimit,
          offset: this.followersOffset,
        });
        this.userFollowers = usrFollws.data.user_follower;
      } catch (e) {
        console.log('Not working', e);
      }
    },
    async follow() {
      this.btnLoading = true;
      if (this.followed || this.followingUser) {
        try {
          await unFollowUser(this.userData.id, this.user.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = false;
          this.followersCount -= 1;
        } catch (e) {
          this.btnLoading = false;
        }
      } else {
        try {
          await followUser(this.userData.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = true;
          this.followersCount += 1;
        } catch (e) {
          this.btnLoading = false;
          console.log(e);
        }
      }
    },
    async followCompany() {
      if (this.followingCompany || this.companyFollowed) {
        console.log('Golfxxx=>');
        console.log(this.companyData);
        try {
          await unFollowCompany({
            companyId: this.companyData.id,
            userId: this.user.id,
          });
          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.companyFollowed = false;
          this.followersCount -= 1;
        } catch (e) {
          console.log(e);
          this.companyFollowed = true;
        }
      } else {
        try {
          await followCompany(this.companyData.id);
          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.companyFollowed = true;
          this.followersCount += 1;
        } catch (e) {
          this.companyFollowed = false;
        }
      }
    },
    getCompanyData() {
      getCompany(this.$route.params?.companyId).then((res) => {
        this.companyData = res.data.company_by_pk;
        this.followersCount = res.data.company_by_pk.company_followers.length;
      });
    },
    goToBusinessPlan() {
      this.$router.push({
        name: 'Share',
        query: {
          company: this.$route.params?.companyId,
          page: 'overview',
          tab: 'business-plan',
        },
      });
    },
    getTeam() {
      getCompanyMembers({
        companyId: this.$route.params?.companyId,
        isAdvisory: false,
      })
        .then((res) => {
          this.members = res.data.team;
        })
        .catch((e) => {
          console.log({ e });
        });
    },
    getCompanyPosts() {
      getCompanyPosts({
        companyId: this.$route.params?.companyId,
        limit: 10,
        offset: 0,
      }).then((res) => {
        this.posts = res.data.post;
      });
    },
    getUserPosts() {
      getUsersPosts({
        userId: this.$route.params?.userId,
        limit: 10,
        offset: 0,
      }).then((res) => {
        this.posts = res.data.post;
      });
    },
    // getFoundationData() {
    //   this.isFetchingData = true;
    //   getFoundationByType({
    //     type: 'vision',
    //     companyId: this.$route.params?.companyId,
    //   }).then((res) => {
    //     console.log(res.data);
    //   });
    // },

    getUserData() {
      getUser(this.$route.params?.userId).then((res) => {
        this.userData = res.data.user_by_pk;
      });
    },
    closeCroppedModal() {
      this.showCropped = false;
      this.croppedImage = null;
    },
    onBackgroundChange(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.backgroundImage.url = e.target.result;
        };
        reader.readAsDataURL(file);

        if (this.userData) {
          this.uploadUserCoverImage(file);
        } else {
          this.uploadCompanyBg(file);
        }
      }
    },
    async onProfileChange(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.croppedImage = e.target.result;
        };
        reader.readAsDataURL(file);

        this.showCropped = true;
      }
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();

      canvas.toBlob(async (blob) => {
        const file = new File([blob], this.uploadedProfile.file.name, {
          type: blob.type,
        });

        console.log(file);

        if (this.isCompany) {
          await this.uploadCompanyLogo(file);
        } else {
          await this.uploadFile(file);
        }
      }, 'image/png');

      this.uploadedProfile.url = canvas.toDataURL();
      this.showCropped = false;
    },
    async uploadCompanyLogo(file) {
      try {
        const res = await uploadFile({ file, type: 'image' });
        await updateCompany({
          id: this.companyData.id,
          set: { logo: res.data.url },
        });
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating company logo picture`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async uploadCompanyBg(file) {
      try {
        const res = await uploadFile({ file, type: 'image' });
        await updateCompany({
          id: this.companyData.id,
          set: { coverImage: res.data.url },
        });
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating banner picture`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async uploadUserCoverImage(file) {
      try {
        const res = await uploadFile({ file, type: 'image' });
        console.log('Sgix==>', file);
        await updateUser({
          id: this.user.id,
          set: { coverImage: res.data.url },
        });
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating banner picture`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async uploadFile(file) {
      try {
        const res = await uploadFile({ file, type: 'image' });
        await updateUser({
          id: this.user.id,
          set: { profilePhoto: res.data.url },
        });
        this.updateUserState({ profilePhoto: res.data.url });
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Error while updating profile picture`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    openImageChange() {
      this.$refs.profileFileInput.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_cover {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.profile_i_sectn {
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
  margin-top: -60px;

  img {
    height: 120px;
    width: 150px;
    border-radius: 15px;
  }

  .follows {
    margin-left: 20px;

    width: 100%;

    .profile_foll {
      display: flex;
      justify-content: space-between;

      .follow_count {
        display: flex;
        align-items: center;
      }

      .follow_btn {
        display: flex;
      }
    }
  }
}

button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
