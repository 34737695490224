<template>
  <div class="p-1">
    <c-flex p="10px" w="100%">
      <c-box w="100%">
        <c-tabs v-if="hasFollowers" variant-color="vc-orange">
          <c-tab-list>
            <c-tab><small>Followers</small></c-tab>
          </c-tab-list>

          <c-tab-panels w="100%">
            <c-tab-panel>
              <div class="p-5" style="background-color: #dfe9f866">
                <c-spinner
                  ml="2"
                  color="orange"
                  thickness="2px"
                  v-if="dataLoading"
                />
                <div v-else>
                  <c-flex
                    flexWrap="wrap"
                    justify="start"
                    align="center"
                    v-if="hasFollowers"
                  >
                    <company-follower-card
                      v-for="foll in companyFollowers"
                      :key="foll.follower.id"
                      :user="foll.follower"
                    />
                  </c-flex>
                  <p v-else>Has no followers</p>
                </div>
              </div>
            </c-tab-panel>
          </c-tab-panels>
        </c-tabs>
        <div v-else>No Connections yet</div>
      </c-box>
    </c-flex>
  </div>
</template>

<script>
import CompanyFollowerCard from '../../components/CompanyFollowerCard.vue';
import {getCompanyFollowers} from "@/services/company";
import {mapGetters} from "vuex";

export default {
  name: 'NetworkPage',
  components: {
    CompanyFollowerCard
  },
  data() {
    return {
      dataLoading: true,
      companyFollowers: [],
    };
  },
  props: ['isFollowed'],
  computed: {
    companyId() {
      return this.$route.params?.companyId;
    },
    hasFollowers() {
      return this.companyFollowers.length > 0;
    },
    ...mapGetters({
      user: "auth/getUser"
    })
  },
  created() {

    this.getConnectionsData();
  },

  watch: {
    isFollowed(newVal){
      if(newVal === true){
        this.companyFollowers.push(
            {
              follower: this.user
            }
        )
      }
      else if(newVal === false){
        this.companyFollowers = this.companyFollowers.filter((flw) => flw.follower.id !== this.user.id)
      }
    }
  },
  methods: {
    async getConnectionsData() {
      try {
        const folowers = await getCompanyFollowers(this.companyId)

        this.companyFollowers = folowers.data.company_follower;
        this.dataLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
