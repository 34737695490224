import { render, staticRenderFns } from "./CompanyFollowerCard.vue?vue&type=template&id=28a45ea2&scoped=true"
import script from "./CompanyFollowerCard.vue?vue&type=script&lang=js"
export * from "./CompanyFollowerCard.vue?vue&type=script&lang=js"
import style0 from "./CompanyFollowerCard.vue?vue&type=style&index=0&id=28a45ea2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a45ea2",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox})
