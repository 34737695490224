import { render, staticRenderFns } from "./TeamCard.vue?vue&type=template&id=a9e8d948"
import script from "./TeamCard.vue?vue&type=script&lang=js"
export * from "./TeamCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CAvatar: require('@chakra-ui/vue').CAvatar, CFlex: require('@chakra-ui/vue').CFlex})
