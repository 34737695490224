<template>
  <c-flex
      flexDirection="row"
      flexWrap="wrap"
      p="4"
  >
    <!-- <c-text fontSize="1rem" fontWeight="bold">Companies</c-text> -->
    <c-box
        mb="3"
        mr="3"
        w="220px"
        align="center"
        v-for="(company, i) in companies"
        :key="i"
    >
      <company-item-card :company="company" />
    </c-box>
  </c-flex>
</template>

<script>
import CompanyItemCard from '@/views/App/Profile/components/CompanyItemCard.vue';
export default {
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CompanyItemCard,
  },
  methods: {
    goToProfile(companyId) {
      this.$router.push({ name: 'CompanyProfile', params: { companyId } });
    },
    // goToCompany(company) {
    //   window.open(
    //     `${location.origin}/app/share?company=${company.id}&page=overview&tab=business-plan`
    //   );
    // },
  },
};
</script>
