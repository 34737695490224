<template>
  <c-box mt="8">
    <c-text mb="2" px="4" fontSize="15px" fontWeight="600"
      >Post Activities</c-text
    >
    <c-box py="4" px="4">
      <post-card v-for="(post, i) in posts.slice(0, 5)" :key="i" :post="post" />
    </c-box>
  </c-box>
</template>

<script>
// import ActivityItem from './ActivityItem.vue';
// import NewsCard from '../../../Insight/components/NewsCard.vue';
import PostCard from '../../../Explore/components/PostCard.vue'

export default {
  props: {
    posts: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    // NewsCard,
    PostCard
  },
};
</script>
