<template>
  <c-box
    mt="8"
    py="4"
    px="4"
    boxShadow="1px 1px 4px rgba(75, 102, 171, 0.20)"
    minH="9rem"
  >
    <c-text fontSize=".8rem" fontWeight="bold">Elevator Pitch</c-text>
    <c-flex v-if="text" mt="4">
      <c-text fontSize=".9rem" v-html="text"> </c-text>
    </c-flex>
    <c-flex v-else>
      <c-text>Nothing added yet</c-text>
    </c-flex>
  </c-box>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis accusamus
        consectetur rem, odit mollitia totam iste sed aperiam! Id tempora
        repellat inventore quis alias perferendis sequi deserunt sit in eos!
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores
        debitis voluptas saepe. Id unde repudiandae veniam blanditiis quaerat,
        nesciunt vitae quis, natus assumenda suscipit officiis, est vero quas?
        Cum, ratione.`,
    },
  },
};
</script>
