import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=0f930b5c&scoped=true"
import script from "./Profile.vue?vue&type=script&lang=js"
export * from "./Profile.vue?vue&type=script&lang=js"
import style0 from "./Profile.vue?vue&type=style&index=0&id=0f930b5c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f930b5c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CIconButton: require('@chakra-ui/vue').CIconButton, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CIcon: require('@chakra-ui/vue').CIcon, CGrid: require('@chakra-ui/vue').CGrid, CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CTabPanel: require('@chakra-ui/vue').CTabPanel, CTabPanels: require('@chakra-ui/vue').CTabPanels, CTabs: require('@chakra-ui/vue').CTabs, CSpinner: require('@chakra-ui/vue').CSpinner, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal})
